/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../core/Accessor.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { clone as i } from "../core/lang.js";
import { getMetersPerUnitForSR as o, getUnitString as s } from "../core/unitUtils.js";
import { Integer as n } from "../core/accessorSupport/ensureType.js";
import { property as l } from "../core/accessorSupport/decorators/property.js";
import { subclass as a } from "../core/accessorSupport/decorators/subclass.js";
import { writer as p } from "../core/accessorSupport/decorators/writer.js";
import { wgs84 as u, webMercator as d, plateCarree as c, isWGS84 as m, isWebMercator as w, isGeographic as y, isWrappable as k, equals as h } from "./support/spatialReferenceUtils.js";
var S;
let f = S = class extends r {
  static fromJSON(e) {
    if (!e) return null;
    if (102100 === e?.wkid) return S.WebMercator;
    if (4326 === e?.wkid) return S.WGS84;
    const t = new S();
    return t.read(e), t;
  }
  constructor(e) {
    super(e), this.latestWkid = null, this.wkid = null, this.wkt = null, this.wkt2 = null, this.vcsWkid = null, this.latestVcsWkid = null, this.imageCoordinateSystem = null;
  }
  normalizeCtorArgs(e) {
    if (e && "object" == typeof e) return e;
    const t = "string" == typeof e ? "wkt" : "wkid";
    return {
      [t]: e
    };
  }
  get isWGS84() {
    return m(this);
  }
  get isWebMercator() {
    return w(this);
  }
  get isGeographic() {
    return y(this);
  }
  get isWrappable() {
    return k(this);
  }
  get metersPerUnit() {
    return o(this);
  }
  get unit() {
    return s(this) || (this.isGeographic ? "degrees" : null);
  }
  writeWkt(e, t) {
    this.wkid || (t.wkt = e);
  }
  clone() {
    if (this === S.WGS84) return new S(u);
    if (this === S.WebMercator) return new S(d);
    const e = new S();
    return null != this.wkid ? (e.wkid = this.wkid, null != this.latestWkid && (e.latestWkid = this.latestWkid), null != this.vcsWkid && (e.vcsWkid = this.vcsWkid), null != this.latestVcsWkid && (e.latestVcsWkid = this.latestVcsWkid)) : (null != this.wkt && (e.wkt = this.wkt), null != this.wkt2 && (e.wkt2 = this.wkt2)), this.imageCoordinateSystem && (e.imageCoordinateSystem = i(this.imageCoordinateSystem)), e;
  }
  equals(e) {
    if (null == e) return !1;
    if (this.imageCoordinateSystem || e.imageCoordinateSystem) {
      if (null == this.imageCoordinateSystem || null == e.imageCoordinateSystem) return !1;
      const {
          id: t,
          referenceServiceName: r
        } = e.imageCoordinateSystem,
        {
          geodataXform: i
        } = e.imageCoordinateSystem,
        o = this.imageCoordinateSystem;
      return null == t || i ? JSON.stringify(o) === JSON.stringify(e.imageCoordinateSystem) : r ? o.id === t && o.referenceServiceName === r : o.id === t;
    }
    return h(this, e);
  }
  toJSON(e) {
    return this.write(void 0, e);
  }
};
f.GCS_NAD_1927 = null, f.WGS84 = null, f.WebMercator = null, f.PlateCarree = null, e([l({
  readOnly: !0
})], f.prototype, "isWGS84", null), e([l({
  readOnly: !0
})], f.prototype, "isWebMercator", null), e([l({
  readOnly: !0
})], f.prototype, "isGeographic", null), e([l({
  readOnly: !0
})], f.prototype, "isWrappable", null), e([l({
  type: n,
  json: {
    write: !0
  }
})], f.prototype, "latestWkid", void 0), e([l({
  readOnly: !0
})], f.prototype, "metersPerUnit", null), e([l({
  readOnly: !0
})], f.prototype, "unit", null), e([l({
  type: n,
  json: {
    write: !0,
    origins: {
      "web-scene": {
        write: {
          overridePolicy() {
            return {
              isRequired: null == this.wkt
            };
          }
        }
      }
    }
  }
})], f.prototype, "wkid", void 0), e([l({
  type: String,
  json: {
    origins: {
      "web-scene": {
        write: {
          overridePolicy() {
            return {
              isRequired: null == this.wkid
            };
          }
        }
      }
    }
  }
})], f.prototype, "wkt", void 0), e([p("wkt"), p("web-scene", "wkt")], f.prototype, "writeWkt", null), e([l({
  type: String,
  json: {
    write: !1
  }
})], f.prototype, "wkt2", void 0), e([l({
  type: n,
  json: {
    write: !0
  }
})], f.prototype, "vcsWkid", void 0), e([l({
  type: n,
  json: {
    write: !0
  }
})], f.prototype, "latestVcsWkid", void 0), e([l()], f.prototype, "imageCoordinateSystem", void 0), f = S = e([a("esri.geometry.SpatialReference")], f), f.prototype.toJSON.isDefaultToJSON = !0, f.GCS_NAD_1927 = t.freeze(new f({
  wkid: 4267,
  wkt: 'GEOGCS["GCS_North_American_1927",DATUM["D_North_American_1927",SPHEROID["Clarke_1866",6378206.4,294.9786982]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]]'
})), f.WGS84 = t.freeze(new f(u)), f.WebMercator = t.freeze(new f(d)), f.PlateCarree = t.freeze(new f(c));
const g = f;
export { g as default };